<template>
  <v-card>
    <TopKeystoneToolbar
      entity-type="company"
      v-model="search"
      :open-new="openNewCompany"
      v-on:refresh="refresh"
    >
    </TopKeystoneToolbar>

    <CompanyForm
      :is-edit-form.sync="isEditForm"
      :entity-id="currentCompanyId"
      v-model="companyDialogForm"
      :default-entity="defaultEntity"
      v-on:refresh="refresh"
      ref="companyForm"
    >

    </CompanyForm>
    <HoldingForm
      v-model="holdingDialogForm"
      :is-edit-form="true"
      :entity-id="parentHoldingId"
      v-on:refresh="refresh"
      ref="holdingForm"
    >
    </HoldingForm>
    <div class="entity-view-div">
      <v-data-table
        :headers-length="5"
        :loading="$store.getters.getLoadingCompanies"
        :items="companies"
        :headers="headers"
        :options.sync="pagination"
        :server-items-length="totalItems"
        @update:options="callApi"
        :footer-props="{
          itemsPerPageOptions: rowPerPageItem
        }"
      >
        <template v-slot:item="props">
          <tr>
            <td>
              {{ props.item.id }}
            </td>
            <td>
              <strong>
                {{ props.item.name }}
              </strong>
            </td>
            <td>
              <v-icon small>
                launch
              </v-icon>
              <a @click="openEditParentHolding(props.item.holding.id)">{{ props.item.holding.name }}</a>
            </td>
            <td>
              <v-icon small>
                launch
              </v-icon>
              <a @click="goToSiege(props.item)"> Seats of {{props.item.name}} </a>
            </td>
            <td>
              <v-icon
                :color="props.item.has_autoplugs ? 'green' : 'red'"
              >
                power_settings_new
              </v-icon>
            </td>
            <td>
              <v-btn icon>
                <v-icon @click="openEditCompany(props.item.id)">
                  edit
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import store from '../../../store'
import { keystoneV2 } from '../../../store/modules/keystoneV2'
import * as keystoneV2Utils from '../../../utils/keystoneV2Utils'
import CompanyModel from '@/models/Keystone_v2/CompanyModel'
import TopKeystoneToolbar from '@/components/KeystoneV2/TopKeystoneToolbar'
import CompanyForm from '@/components/KeystoneV2/Form/CompanyForm'
import HoldingForm from '@/components/KeystoneV2/Form/HoldingForm'
import { openFormOptionMixin } from '@/mixins/openFormOptionMixin'
import { ROW_PER_PAGE_ITEM } from '@/components/KeystoneV2/commonConstantKeystone'
import { keystoneTabViewMixin } from '@/mixins/keystoneTabViewMixin'
import { getEntityApiPayload } from '@/components/KeystoneV2/payloadHelperKeystone'
import _ from 'lodash'
// load the store module dynamically only when needed
if (!store.state.keystoneV2) store.registerModule('keystoneV2', keystoneV2)

export default {
  name: 'CompanyView.vue',
  components: {
    TopKeystoneToolbar,
    CompanyForm,
    HoldingForm
  },
  mixins: [openFormOptionMixin, keystoneTabViewMixin],
  data: function () {
    return {
      headers: [
        { text: 'Internal ID', value: 'id', width: '15px', sortable: false },
        { text: 'Company name', value: 'name' },
        { text: 'Holding name', value: 'holding.name' },
        { text: 'Seats', sortable: false },
        { text: 'Autoplugs', value: 'has_autoplugs' },
        { text: 'Action', sortable: false }
      ],
      search: '',
      isEditForm: false,
      companyDialogForm: false,
      holdingDialogForm: false,
      currentCompanyId: null,
      parentHoldingId: null,
      defaultEntity: null,
      entityType: 'company',
      rowPerPageItem: ROW_PER_PAGE_ITEM,
      pagination: {}
    }
  },
  async mounted () {
    await this.callApiIfRightTab()
    if (this.$store.getters.getEditBilling && this.$store.getters.getEditBillingID !== 0 && this.$route.query.type === 'company') {
      this.openEditCompany(this.$store.getters.getEditBillingID)
      this.$store.commit('resetEditBilling')
    }
  },
  methods: {
    async callApi () {
      const payload = getEntityApiPayload(this.search, this.pagination, 'companyId')
      await this.$store.dispatch('getApiCompanies', payload)
    },
    async refresh () {
      if (this.$store.getters.getLoadingCompanies) {
        return
      }
      await this.callApi()
    },
    goToSiege (company) {
      const option = {
        text: company.name,
        id: company.id,
        isParent: true,
        source: 'company'
      }
      this.$store.commit('setTabSearchSieges', option)
      const tab = keystoneV2Utils.getTabForEntity('siege')
      this.$store.commit('setCurrentTab', tab)
    },
    /**
     * @param holding {HoldingModel}
     */
    openNewCompany (holding = null) {
      this.isEditForm = false
      this.defaultEntity = new CompanyModel()
      this.defaultEntity.hideInsight = true
      this.$refs.companyForm.defineMsaTypeItemsForNewForm()

      if (holding) {
        this.defaultEntity.holding = holding
      }

      this.currentCompanyId = null
      this.companyDialogForm = true
    },
    openEditCompany (companyId) {
      this.isEditForm = true
      this.companyDialogForm = true
      this.currentCompanyId = companyId
    },
    openEditParentHolding (holdingId) {
      this.holdingDialogForm = true
      this.parentHoldingId = holdingId
    },
    /**
     * called by open form mixin
     * @param holding
     */
    openNew (holding = null) {
      this.openNewCompany(holding)
    },
    /**
     * called by open form mixin
     * @param companyId
     */
    openEdit (companyId) {
      this.openEditCompany(companyId)
    }
  },
  computed: {
    companies () {
      return this.$store.getters.getCompanies
    },
    totalItems: function () {
      return this.$store.getters.getTotalCountCompanies
    }
  },
  watch: {
    '$store.getters.getTabSearchCompanies': {
      immediate: false,
      deep: true,
      handler: async function (newValue) {
        this.pagination.page = 1
        await this.callApi()
      }
    },
    search: _.debounce(function () {
      this.callApi()
    }, 600)
  }
}
</script>

<style scoped>
.dsp-img {

}
</style>
